<!--
 * @Descripttion: 登录页面
 * @version: v1.0.0
 * @Author: zhangqinggang
 * @Date: 2020-11-17 09:20:14
 * @LastEditors: Please set LastEditors
 * @LastEditTime: 2021-04-02 15:45:58
-->
<template>
  <div class="loginContainer">
    <div class="loginBox">
      <div class="loginBoxL">
        <div class="logo"><img src="@/assets/img/logo.png" alt="" /></div>
        <span class="texOne">智慧校园云平台</span>
        <span class="texTwo">优秀的统一集控管理平台</span>
      </div>
      <div class="loginBoxR">
        <div class="loginBoxTab">
          <button
            class="loginTab"
            :class="{ loginTabHover: loginActive }"
            @click="showLogin()"
          >
            账户密码登录
          </button>
          <!-- <button
            class="sginTab"
            :class="{ sginTabHover: sginActive }"
            @click="showFromSgin()"
          >
            手机号码登录
          </button> -->
        </div>
        <!-- 登录界面-1 -->
        <div class="loginFrom" v-show="showLoginOne" style="display: none">
          <!-- 子组件 -->
          <LoginUser />
          <!--  -->
        </div>
        <!-- 登录界面-1 结束 -->
        <!-- 登录结束-2 -->
        <div class="loginFrom" v-show="showLoginTwo">
          <LoginMobile />
          <!--  -->
        </div>
      </div>
      <!-- 登录结束-2 -->
    </div>
    <!-- 底部信息 -->
    <span class="layer">
      ©2023&nbsp;&nbsp;&nbsp;
      <a
        href="https://www.beian.gov.cn/portal/registerSystemInfo"
        target="_blank"
        >苏公网安备32011502011289号</a
      >
      &nbsp;&nbsp;&nbsp;
      <a href="https://beian.miit.gov.cn" target="_blank"
        >苏ICP备2020065401号-2</a
      >
    </span>
    <!-- 底部信息 -->
  </div>
</template>

<script>
import LoginUser from "./login-item/login-user";
import LoginMobile from "./login-item/login-mobile";
export default {
  data() {
    return {
      showLoginOne: "true",
      showLoginTwo: "",
      loginActive: "ture",
      sginActive: "",
    };
  },
  components: { LoginUser, LoginMobile },
  methods: {
    /**
     * @Author: zhangqinggang
     * @description: 切换显示

     */
    showLogin() {
      this.showLoginOne = true;
      this.showLoginTwo = false;
      this.loginActive = true;
      this.sginActive = "";
    },
    showFromSgin() {
      this.showLoginOne = false;
      this.showLoginTwo = true;
      this.sginActive = true;
      this.loginActive = "";
    },
    /**
     * @Author: zhangqinggang
     * @description: 路由跳转

     */
    goMain() {
      this.$router.push("main");
    },

    goForget() {
      this.$router.push("forget");
    },
    goRegister() {
      this.$router.push("register");
    },
    goscanCode() {
      this.$router.push("scan");
    },
  },
};
</script>

<style lang="scss" scope>
@import "./login.scss";
</style>
